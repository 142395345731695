import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const url = "https://new.amazingjackie.com/backend/request.php";

const options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  media_url = "https://new.amazingjackie.com/media/";

  constructor(private http: HttpClient) {
  }

  GetSystemData(): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_system_data', "");

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  StoreAccountLogin(email, password): Promise<any> {
    let body = new URLSearchParams();
    body.set('store_account_login', JSON.stringify({ email: email, password: password }));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  StaffLogin(email, password): Promise<any> {
    let body = new URLSearchParams();
    body.set('staff_login', JSON.stringify({ username: email, password: password }));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  RegisterStore(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('register_store', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  VerifyStoreAccount(email, latest_update_date): Promise<any> {
    let body = new URLSearchParams();
    body.set('verify_store_account', JSON.stringify({ email: email, latest_update_date: latest_update_date }));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }


  DeleteDataByDataTypeAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('delete_data_by_data_type_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetExtraStoreAccountData(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('cms_load_store_account', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetSingleDataByDataTypeAndApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_single_data_by_data_type_and_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetSingleDataByDataTypeAndApplicationKeyAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_single_data_by_data_type_and_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAdditionalProductDataByApplicationKeyAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_additional_product_data_by_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetCouponDataByApplicationKeyAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_coupon_data_by_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetOrderDataByApplicationKeyAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_order_data_by_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetUserDataByApplicationKeyAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_user_data_by_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetMediEnquiryDataByApplicationKeyAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_medi_enquiry_data_by_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(data);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetEnquiryDataByApplicationKeyAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_enquiry_data_by_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(data);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetCurrentPurchasedPlanExcludingDisabledByApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_current_purchased_plan_exluding_disabled_by_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetPurchasedPlanExcludingDisabledByApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_purchased_plan_exluding_disabled_by_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateStoreWebsite(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_store_website', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdatePage(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_page', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdatePageLink(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_page_link', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  CreateNewCustomPage(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('create_new_custom_page', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateStoreAccount(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_store_account', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateStoreAccountSubDomain(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_store_account_sub_domain', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetProductDataAndTotalNumberBySortingAndLimitMergeWithOrderQuantity(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_product_data_and_total_number_by_sorting_and_limit_merge_with_order_quantity', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetProductDataAndTotalNumberBySortingAndLimit(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_product_data_and_total_number_by_sorting_and_limit', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetStoreDataAndTotalNumber(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('staff_get_store', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  StorePageRegen(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('store_regen', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetMediAppointmentDataAndTotalNumberBySortingAndLimitMergeWithOrderQuantity(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_medi_appointment_data_and_total_number_by_sorting_and_limit_merge_with_order_quantity', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAppointmentDataAndTotalNumberBySortingAndLimitMergeWithOrderQuantity(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_appointment_data_and_total_number_by_sorting_and_limit_merge_with_order_quantity', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetStoreAccountDataExcludingDisableByApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_store_account_data_excluding_disabled_by_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetMediEnquiryDataAndTotalNumberBySortingAndLimitMergeWithOrderQuantity(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_medi_enquiry_data_and_total_number_by_sorting_and_limit_merge_with_order_quantity', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetEnquiryDataAndTotalNumberBySortingAndLimitMergeWithOrderQuantity(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_enquiry_data_and_total_number_by_sorting_and_limit_merge_with_order_quantity', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetProductCategoryDataAndTotalNumberBySortingAndLimitMergeWithOrderQuantity(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_product_category_data_and_total_number_by_sorting_and_limit_merge_with_order_quantity', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(data);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetProductCategorys(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_data_by_data_type_and_application_key_and_limit_and_page_and_order', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetProductCategoryByID(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_single_data_by_data_type_and_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetCourseCategoryByID(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_single_data_by_data_type_and_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetMediAppointmentCategoryByID(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_single_data_by_data_type_and_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAppointmentCategoryByID(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_single_data_by_data_type_and_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateProductCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_product_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateCourseCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_course_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateMediAppointmentCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_medi_appointment_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateAppointmentCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_appointment_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  AddProductCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_product_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  AddCourseCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_course_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllCourseDataByCourseCodeAndApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_course_data_by_course_code_and_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateAllCourseByCourseCode(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_course_by_course_code', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateCourseDataList(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_course_data_list', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  CreateCourseDataByList(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('create_course_data_by_list', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetNewCourseTemplateData(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_new_course_template_data', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewCourse(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_course', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetCourseDataAndTotalNumberBySortingAndLimitMergeWithOrderQuantity(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_course_data_and_total_number_by_sorting_and_limit_merge_with_order_quantity', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  AddMediAppointmentCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_medi_appointment_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  AddAppointmentCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_appointment_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  RemoveProductCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_product_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  RemoveCourseCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_course_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  RemoveAppointmentCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_medi_appointment_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  RemoveRawAppointmentCategory(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('remove_appointment_category', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetCouponDataAndTotalNumberBySortingAndLimitOrSearch(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_coupon_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetUserDataAndTotalNumberBySortingAndLimitOrSearch(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_user_data_and_total_number_by_sorting_and_limit_or_search', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetOrderDataAndTotalNumberBySortingAndLimit(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_order_data_and_total_number_by_sorting_and_limit', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAppopintmentDataAndTotalNumberBySortingAndLimit(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_appointment_record_data_and_total_number_by_sorting_and_limit', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(data);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetRawAppointmentDataAndTotalNumberBySortingAndLimit(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_raw_appointment_record_data_and_total_number_by_sorting_and_limit', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(data);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  SearchProductByLimitAndPage(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('search_product_by_limit_and_page', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllProductDataByProductCodeAndApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_product_data_by_product_code_and_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateAllProductByProductCode(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_product_by_product_code', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateProduct(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_product', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateCoupon(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_coupon', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewProduct(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_product', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewCoupon(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('new_coupon', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateProductDataList(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_product_data_list', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateAppointmentDataList(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_appointment_data_list', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateRawAppointmentDataList(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_raw_appointment_data_list', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  CreateProductDataByList(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('create_product_data_by_list', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetNewProductTemplateData(send_data = null): Promise<any> {
    let body = new URLSearchParams();
    if (send_data != null) {
      body.set('get_new_product_template_data', JSON.stringify(send_data));
    } else {
      body.set('get_new_product_template_data', "");
    }


    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UploadBase64FileToServer(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('upload_base64_file_to_server', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllDataByDataTypeAndApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_data_by_data_type_and_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllPageDataByApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_page_data_by_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllProductDataExcludingAdditionalProductByApplicationKeyWithCustomReturnOrderByIndex(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_product_data_excluding_additional_product_by_application_key_with_custom_return_order_by_index', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllAppointmentDataByApplicationKeyWithCustomReturnOrderByIndex(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_medi_appointment_order_by_index', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllRawAppointmentDataByApplicationKeyWithCustomReturnOrderByIndex(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_appointment_order_by_index', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }


  UpdateOrder(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_order', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateOrderStatus(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_order_status', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdatePayment(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_payment', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllUserDataByApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_user_data_by_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewMediAppointment(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('add_medi_appointment_setting', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  NewAppointment(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('add_appointment_setting', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateMediAppointment(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_medi_appointment_setting', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateAppointment(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_appointment_setting', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateMediAppointmentRecord(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_medi_appointment', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdateAppointmentRecord(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_appointment', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetMediAppointmentOfMonth(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_medi_appointment_of_month', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        console.log(temp);
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetMediAppointmentDataByApplicationKeyAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_order_data_by_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAppointmentDataByApplicationKeyAndId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_order_data_by_application_key_and_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllDataExcludingDisabledByDataTypeAndApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_data_excluding_disabled_by_data_type_and_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllProductDataAndAdditionalProductDataExcludingDisabledByProductCategoryIdAndApplicationKey(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_product_data_and_additional_product_data_excluding_disabled_by_product_category_id_and_application_key', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetAllPublicAndPrivateCouponDataExcludingDisabledAndNotUsableByApplicationKeyAndUserId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_public_and_private_coupon_data_excluding_disabled_and_not_usable_by_application_key_and_user_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  CalculateOrderAmount(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('calculate_order_amount', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  Checkout(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('check_out', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  UpdatePurcahsePlan(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_purchase_plan', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });
  }

  GetUserTotalAmountOfCompletedOrderByUserId(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_user_total_amount_of_completed_order_by_user_id', JSON.stringify(send_data));

    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });

  }

  SendProductInventoryEmail(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('send_product_inventory_email', JSON.stringify(send_data));
    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });

  }

  SendAppointmentRemindEmail(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('send_appointment_remind_email', JSON.stringify(send_data));
    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });

  }

  SendCourseFullEmail(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('send_course_full_email', JSON.stringify(send_data));
    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });

  }

  SendCourseStatusEditEmail(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('course_status_edit_email', JSON.stringify(send_data));
    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });

  }

  GetAllDataByDataTypeAndIdList(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('get_all_data_by_data_type_and_id_list', JSON.stringify(send_data));
    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });

  }

  UpdateEmailBoolean(send_data): Promise<any> {
    let body = new URLSearchParams();
    body.set('update_email_boolean', JSON.stringify(send_data));
    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), options).subscribe(async data => {
        // console.log(data);
        let temp = data;
        if (temp['result'] == "success") {
          resolve(temp);
        } else if (temp['result'] == "fail") {
          resolve(temp);
        } else if (temp['result'] == "error") {
          reject(temp);
        }
      }),
        async error => {
          return error;
        };
    });

  }

}






