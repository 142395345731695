import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { AuthService } from "../services/auth.service";
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {

  constructor(
    private httpService: HttpService,
    private commonService: CommonService,
    private router: Router,
    public dataService: DataService,
    private authService: AuthService
  ){

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      let store_account_data = JSON.parse(localStorage.getItem("store_account_data"));
      let internal_account_data = JSON.parse(localStorage.getItem("internal_account_data"));
    // let store_account_data = this.authService.isAuthenticated();
    console.log(store_account_data);
    console.log(internal_account_data);
    if ((store_account_data == undefined || store_account_data == null) && (internal_account_data==undefined || internal_account_data==null)){
      this.dataService.staff_data = null;
      this.authService.StaffLogout();
      return false;
    }
    // if (store_account_data.data_type == undefined ){
    //   this.authService.Logout();
    //   return false;
    // }

      if (internal_account_data==undefined || internal_account_data==null || internal_account_data.username == undefined ){
        // this.authService.Logout();
        if (store_account_data == undefined || store_account_data == null || store_account_data.email == undefined ){
          this.authService.StaffLogout();
          return false;
        }
        else{
          // console.log(store_account_data);
          this.httpService.VerifyStoreAccount(store_account_data.email, store_account_data.latest_update_date).then(res => {
            // console.log(res);
            if (res.result != "success"){
              this.authService.Logout();
            }
            else{
              this.authService.updateUserData(res.data);
            }
          });
          return true;
        }
      }
      else{
          if (store_account_data == undefined || store_account_data == null || store_account_data.email == undefined ){
            // this.authService.Logout();
            // return false;
            return true;
          }
          else{
            // console.log(store_account_data);
            this.httpService.VerifyStoreAccount(store_account_data.email, store_account_data.latest_update_date).then(res => {
              // console.log(res);
              if (res.result != "success"){
                this.authService.Logout();
              }
              else{
                this.authService.updateUserData(res.data);
              }
            });
            return true;
          }
      }
  }
}
