import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  current_url = null;
  store_website_data = null;

  toggleChangeLangArea = false;
  current_lang = "zh";

  language_list = [
    { lang: "zh", path: "zh", name: "中文" },
    { lang: "en", path: "en", name: "English" }
  ];

  constructor(
    public commonService: CommonService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public authService: AuthService,
    private router: Router,
    private location: Location,
    public auth: AuthService,
    public httpService: HttpService,
    public dataService: DataService
  ) {
    this.matIconRegistry.addSvgIcon('my_plan', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icon/my_plan.svg'));
    this.matIconRegistry.addSvgIcon('support', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icon/support.svg'));
    this.matIconRegistry.addSvgIcon('logout', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icon/logout.svg'));
    this.matIconRegistry.addSvgIcon('down_arrow', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icon/down_arrow.svg'));
    // ;
    this.router.events.subscribe(val => {
      if (location.path() != "") {
        this.current_url = this.router.url;
      }
    });
  }

  async ngOnInit() {
    const get_store_website_data = await this.dataService.getStoreWebsiteData();
    if (get_store_website_data.result == "success") {
      this.store_website_data = JSON.parse(JSON.stringify(get_store_website_data['data']));
      if (this.store_website_data.disabled) {
        this.commonService.isStorePublished(false);
      } else {
        this.commonService.isStorePublished(true);
      }

      let key = `site_lang_${this.store_website_data.application_key}`;
      this.current_lang = localStorage.getItem(key);
      if (this.current_lang == null) {
        localStorage.setItem(key, 'zh');
        this.current_lang = 'zh';
      }
    }
    else {
      this.commonService.openErrorSnackBar();
    }
    await this.getSettingAlertData();
  }

  async getSettingAlertData() {
    let store_account_data = JSON.parse(JSON.stringify(this.auth.getStoreAccountData()));
    if (store_account_data != null) {
      this.commonService.updateSettingAlertBarStatus("language", store_account_data.accept_language.length != 0);
      this.commonService.updateSettingAlertBarStatus("currency", store_account_data.accept_currency.length != 0);
      this.commonService.updateSettingAlertBarStatus("payment", store_account_data.payment_method_list.length != 0);
      this.commonService.updateSettingAlertBarStatus("delivery", store_account_data.delivery_method_list.length != 0);
    }

    if (this.store_website_data != null) {
      this.commonService.updateSettingAlertBarStatus("icon", this.store_website_data.logo_img_url != '');
    }

    let send_data = {
      sorting: "create_date",
      direction: "DESC",
      limit: 10,
      page: 1,
      application_key: this.auth.getStoreAccountData().application_key,
      filter_additional_product: false,
      merge_with_quantity: true
    }

    this.httpService.GetProductDataAndTotalNumberBySortingAndLimitMergeWithOrderQuantity(send_data).then(res => {
      if (res.result == 'success') {
        this.store_website_data.table_data_total_number = res.data.total_number;
        this.commonService.updateSettingAlertBarStatus("product", res.data.total_number > 0);

      } else {
        this.commonService.openErrorSnackBar();
      }
    });

  }

  /*
    openDisableStoreDialog(disable){
      this.commonService.openDisableStoreDialog(disable,this.store_website_data.id, this.store_website_data.disabled);
        }
  
        */

  toggleChangeLang(e) {
    e.stopPropagation();
    this.toggleChangeLangArea = !this.toggleChangeLangArea;
    if (this.toggleChangeLangArea) {
      document.getElementById("expand_icon").innerHTML = "expand_less";
      document.getElementById("lang_selection_area").style.display = "block";

    } else {
      document.getElementById("expand_icon").innerHTML = "expand_more";
      document.getElementById("lang_selection_area").style.display = "none";
    }
  }

  changeLang(e, lang) {
    if (this.current_lang === lang) {
      e.stopPropagation();
      return;
    }
    let key = `site_lang_${this.auth.getStoreAccountData().application_key}`;
    localStorage.setItem(key, lang);
    this.commonService.refresh();
  }
}
