import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {

  @Input() img_url_list: [];
  @Input() initial_index;
  @Input() location;

  slideOpts = {
    // zoom: {
    //   maxRatio: 3,
    // },
    initialSlide: 0,
    slidesPerView: 1,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
  };

  @ViewChild('IonSlider', { static: false }) IonSlider: IonSlides;
  constructor(
    private modalController: ModalController,
    public httpService: HttpService
  ) {
  }

  ngOnInit() {
    this.slideOpts = {
      // zoom: {
      //   maxRatio: 3,
      // },
      initialSlide: this.initial_index,
      slidesPerView: 1,
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  updateSlider() {
    console.log(123);
    if (this.IonSlider != undefined) {
      // this.IonSlider.updateAutoHeight();
      setTimeout(() => {
        this.IonSlider.update();
      }, 50);
    }
  }

  goNext(){
    this.IonSlider.slideNext();
  }

  goPrevious(){
    this.IonSlider.slidePrev();
  }
}
