import { Injectable, Component, Inject, HostListener, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  NavController,
  MenuController,
  AlertController,
  ModalController,
} from "@ionic/angular";
import { HttpService } from "./http.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { GalleryComponent } from "../components/gallery/gallery.component";

export interface DialogData {
  disable: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public show_sidemenu = true;
  public isLoading = false;

  public show_aj = false;

  public dark_mode = false;

  private settingAlertBarStatus = {
    language: false,
    currency: false,
    payment: false,
    delivery: false,
    icon: false,
    product: false,
  };

  private showSettingAlertBar = true;
  public showDisableStoreBar = false;
  public published = false;

  public current_lang = 'zh';

  constructor(
    private _snackBar: MatSnackBar,
    private nav: NavController,
    public menuCtrl: MenuController,
    private alertController: AlertController,
    private httpService: HttpService,
    public dialog: MatDialog,
    private modalController: ModalController
  ) {
    if (window.innerWidth <= 992) {
      this.show_sidemenu = false;
    }

    let store_website_data = JSON.parse(localStorage.getItem("store_account_data"));
    if (store_website_data != null) {
      let key = `site_lang_${store_website_data.application_key}`;
      this.current_lang = localStorage.getItem(key);
      if (this.current_lang == null) {
        localStorage.setItem(key, 'zh');
        this.current_lang = 'zh';
      }
    }
  }

  remove_duplicate_from_array(arr) {
    return arr.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
  }

  toggleMenu() {
    if (this.show_sidemenu) {
      this.show_sidemenu = false;
      // this.menuCtrl.close();
    } else {
      this.show_sidemenu = true;
      // setTimeout(() => {
      //   this.menuCtrl.open();
      // }, 100);
    }
    // this.menuCtrl.toggle();
  }

  isStorePublished(published: boolean) {
    if (published) {
      this.published = true;
      this.showDisableStoreBar = false;

    } else {
      this.published = false;
      this.showDisableStoreBar = true;
    }
  }

  updateSettingAlertBarStatus(type: String, status: boolean) {
    switch (type) {
      case "language":
        this.settingAlertBarStatus.language = status;
        break;
      case "currency":
        this.settingAlertBarStatus.currency = status;
        break;
      case "payment":
        this.settingAlertBarStatus.payment = status;
        break;
      case "delivery":
        this.settingAlertBarStatus.delivery = status;
        break;
      case "icon":
        this.settingAlertBarStatus.icon = status;
        break;
      case "product":
        this.settingAlertBarStatus.product = status;
        break;
    }

    this.showSettingAlertBar = Object.values(
      this.settingAlertBarStatus
    ).includes(false);

    if (!this.published) {
      this.showDisableStoreBar = !this.showSettingAlertBar;
    }
  }

  async openDisableStoreDialog(disable, id, originalDisableStatus) {
    if (this.isLoading) {
      return;
    }

    let message = "";

    if (this.current_lang === 'zh') {
      if (disable) {
        message = "您確認要隱藏商店?";
      } else {
        message = "您確認要發佈商店?";
      }
    } else {
      if (disable) {
        message = "Are you sure to hide the store?";
      } else {
        message = "Are you sure to publish the store?";
      }
    }



    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: { disable: disable, current_lang: this.current_lang, message: message },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        return this.disableOrEnableStoreWebsiteData(
          disable,
          id,
          originalDisableStatus
        );
      }
    });
  }

  disableOrEnableStoreWebsiteData(disable, id, originalDisableStatus) {
    let send_data = {
      id: id,
      disabled: disable,
    };
    this.isLoading = true;
    this.httpService.UpdateStoreWebsite(send_data).then((res) => {
      this.isLoading = false;
      if (res.result == "success") {
        /*
        this.store_website_data = JSON.parse(JSON.stringify(res.data));
        this.dataService.store_website_data = JSON.parse(JSON.stringify(res.data));
*/

        let store_website_data = JSON.parse(JSON.stringify(res.data));
        this.showDisableStoreBar = store_website_data.disabled;


        if (this.showDisableStoreBar) {
          if (this.current_lang === 'zh') {
            this.openSnackBar("已隱藏商店！");
          } else {
            this.openSnackBar("Your store has been hided");
          }

          this.published = false;
        } else {
          if (this.current_lang === 'zh') {
            this.openSnackBar("已發佈商店！");
          } else {
            this.openSnackBar("Your store has been published!");
          }

          this.isStorePublished(true);
        }
      } else {
        if (this.current_lang === 'zh') {
          if (originalDisableStatus) {
            this.openSnackBar("無法隱藏商店！");
          } else {
            this.openSnackBar("無法發佈商店！");
          }
        } else {
          if (originalDisableStatus) {
            this.openSnackBar("Cannot hide the store!");
          } else {
            this.openSnackBar("Cannot hide the store!");
          }
        }

      }
    });
  }

  openSnackBar(
    message: string,
    button_text?: string,
    durantion?: number,
    horizontalPosition?,
    verticalPosition?
  ) {
    this._snackBar.open(
      message,
      button_text != undefined ? button_text : "OK",
      {
        duration: durantion != undefined ? durantion : 2500,
        horizontalPosition:
          horizontalPosition != undefined ? horizontalPosition : "end",
        verticalPosition:
          verticalPosition != undefined ? verticalPosition : "top",
      }
    );
  }

  openErrorSnackBar(
    message?: string,
    button_text?: string,
    durantion?: number,
    horizontalPosition?,
    verticalPosition?
  ) {
    this._snackBar.open(
      message != undefined ? message : "出現錯誤",
      button_text != undefined ? button_text : "OK",
      {
        duration: durantion != undefined ? durantion : 2500,
        horizontalPosition:
          horizontalPosition != undefined ? horizontalPosition : "end",
        verticalPosition:
          verticalPosition != undefined ? verticalPosition : "top",
        panelClass: "error_snackbar",
      }
    );
  }

  firstFileToBase64(fileImage: File): Promise<{}> {
    return new Promise((resolve, reject) => {
      let fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error("No file found"));
      }
    });
  }

  openPage(url, blank) {
    console.log(url);
    window.open(url, blank ? "_blank" : "_self");
  }

  openCMSPage(url, type) {
    // console.log(window.location.origin);

    switch (type) {
      case "blank":
        window.open(
          (window.location.origin.includes("localhost")
            ? window.location.origin
            : window.location.origin + "/cms") + url,
          "_blank"
        );
        break;
      case "self":
        window.open(
          (window.location.origin.includes("localhost")
            ? window.location.origin
            : window.location.origin + "/cms") + url,
          "_self"
        );
        break;
      case "forward":
        this.nav.navigateForward(url);
        break;
      case "root":
        this.nav.navigateRoot(url);
        break;

      default:
        break;
    }
    // window.open((window.location.origin.includes('localhost') ? window.location.origin : window.location.origin + '/cms') + url, (blank ? '_blank' : '_self'));
  }

  getCMSPage(url) {
    console.log(window.location.origin);
    return (
      (window.location.origin.includes("localhost")
        ? window.location.origin
        : window.location.origin + "/cms") + url
    );
  }

  sort_new_to_old(array, key) {
    return array.sort(function (a, b) {
      var x = b[key];
      var y = a[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  sort_old_to_new(array, key) {
    return array.sort(function (a, b) {
      var x = b[key];
      var y = a[key];
      return x > y ? -1 : x < y ? 1 : 0;
    });
  }

  viewImage(element) {
    if (
      document.getElementById(element) != undefined &&
      document.getElementById(element) != null
    ) {
      document.getElementById(element).click();
    }
  }

  refresh() {
    window.location.reload();
  }

  async deleteDataAlert(data, application_key = null) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      mode: "md",
      header: this.current_lang === 'zh' ? "確定要刪除？" : "Are you sure to delete?",
      // message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: this.current_lang === 'zh' ? "否" : "No",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.current_lang === 'zh' ? "是" : "Yes",
          handler: () => {
            this.deleteData(data, application_key);
          },
        },
      ],
    });

    await alert.present();
  }

  deleteData(data, application_key = null) {
    let send_data = {
      id: data.id,
      data_type: data.data_type,
    };

    if (data.course) {
      send_data["is_course"] = data.course;
      send_data["course_name"] = data.zh_name;
      send_data["price"] = data.price;
      send_data["category"] = data.product_category_id_list;
      send_data["dateAndTime"] = data.schedule_list[0];
      send_data["course_id"] = data.id;
      send_data["reason"] = '';
    }

    if (application_key != null) {
      send_data["application_key"] = application_key;
    }

    this.isLoading = true;
    this.httpService.DeleteDataByDataTypeAndId(send_data).then((res) => {
      console.log(res);
      this.isLoading = false;
      if (res.result == "success") {
        this.refresh();
      } else {

        if (this.current_lang === 'zh') {
          this.openSnackBar("刪除資料失敗！");
        } else {
          this.openSnackBar("Delete unsuccessful!");
        }
      }
    });
  }

  GetDateTimeMatchBackendFormat(date: Date) {
    return (
      new Date(date.getTime() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, -1)
        .split(".")[0] + "Z"
    );
  }

  GetNthDaysAfter(date, days) {
    let d = new Date(new Date(date).setDate(new Date(date).getDate() + days));
    return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
      "0" + d.getDate()
    ).slice(-2)}T${date.slice(11, 19)}Z`;
  }

  GetNthDaysBefore(date, days) {
    let d = new Date(new Date(date).setDate(new Date(date).getDate() - days));
    return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
      "0" + d.getDate()
    ).slice(-2)}T${date.slice(11, 19)}Z`;
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateHongKongPhone(phone) {
    const re = /^-?(0|[1-9]\d*)?$/;
    return re.test(String(phone));
  }

  validateYearStringFormat(date) {
    //YYYY-YYYY
    const re = /^\d{4}-\d{4}$/;
    return re.test(String(date));
  }

  validateDateStringFormat(date) {
    //YYYY-MM-DD
    const re = /^\d{4}-\d{2}-\d{2}$/;
    return re.test(String(date));
  }

  validateHourMinuteStringFormat(date) {
    //YYYY-MM-DD
    const re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return re.test(String(date));
  }

  validatePasswordFormat(date) {
    //Minimum eight characters, at least one letter and one number:
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return re.test(String(date));
  }

  toggleDarkTheme() {
    document.body.classList.toggle("dark-theme", !this.dark_mode);
    this.dark_mode = !this.dark_mode;
    localStorage.setItem("dark_mode", this.dark_mode.toString());
  }

  getFileType(file_type) {
    switch (file_type) {
      case "image/jpeg":
        return "jpg";
      case "image/png":
        return "png";
      case "image/gif":
        return "gif";
      case "application/pdf":
        return "pdf";
      case "application/msword":
        return "doc";

      default:
        return "";
    }
  }

  checkTypeString(data) {
    return typeof data === "string";
  }

  async presentGallery(img_url_list, initial_index, location) {
    const modal = await this.modalController.create({
      component: GalleryComponent,
      componentProps: {
        img_url_list: img_url_list,
        initial_index: initial_index,
        location: location,
      },
      showBackdrop: true,
      backdropDismiss: true,
      swipeToClose: true,
      cssClass: "gallery-modal",
      // presentingElement: this.routerOutlet.nativeEl //REMARK IOS feature
    });
    return await modal.present();
  }
}

@Component({
  selector: "disable-store-dialog",
  templateUrl: "disable-store-dialog.html",
})
@Component({
  selector: "confirm-dialog",
  templateUrl: "confirm-dialog.html",
})
export class ConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

  }

  close() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
