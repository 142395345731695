import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import { MaterialModule } from '../material.module';
import { HeaderComponent } from '../components/header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { GalleryComponent } from '../components/gallery/gallery.component';
import { PopUpComponent } from '../components/popup/popup.component';

@NgModule({
  declarations: [
    HeaderComponent,
    GalleryComponent,
    PopUpComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MaterialModule,
  ],
  exports: [
    HeaderComponent,
    GalleryComponent,
    PopUpComponent
  ]
})
export class SharedComponentsModule { }
