import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopUpComponent implements OnInit {

  // @Input() img_url_list: [];
  // @Input() initial_index;
  // @Input() location;


  //@ViewChild('IonSlider', { static: false }) IonSlider: IonSlides;
  constructor(
    private modalController: ModalController,
    //public httpService: HttpService
  ) {
  }

  ngOnInit() {

  }

  dismiss() {
    this.modalController.dismiss();
  }

 
}
