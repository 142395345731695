import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CommonService } from './common.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  store_website_data = null;
  staff_data = null;
  current_purchased_plan = null;

  table_data_list = null;
  table_data_total_number = null;
  table_data_number_of_page = null;

  constructor(
    public auth: AuthService,
    public commonService: CommonService,
    public httpService: HttpService
  ) { }

  resetTableData(){
    this.table_data_list = null;
    this.table_data_total_number = null;
    this.table_data_number_of_page = null;
  }

  getStoreWebsiteData(): Promise<any> {
    this.auth.ifStoreLoggedIn();
    console.log(this.auth.getStoreAccountData());
    if(this.auth.getStoreAccountData() != null){
      return new Promise((resolve, reject) => {
        let send_data = {
          data_type: "store_website_data",
          application_key: this.auth.getStoreAccountData().application_key
        }
        this.httpService.GetSingleDataByDataTypeAndApplicationKey(send_data).then( res => {
          // console.log(res.data);
          if (res.result == "success"){
            if (JSON.stringify(this.store_website_data) != JSON.stringify(res.data)){
              this.store_website_data = res.data;
            }
          }
          resolve(res);
        });
      });
    }
  }

  getCurrentPurchasedPlan(): Promise<any> {
    if(this.auth.getStoreAccountData() != null){
      return new Promise((resolve, reject) => {
        let send_data = {
          application_key: this.auth.getStoreAccountData().application_key
        }
        this.httpService.GetCurrentPurchasedPlanExcludingDisabledByApplicationKey(send_data).then( res => {
          // console.log(res.data);
          if (res.result == "success"){
            if (JSON.stringify(this.store_website_data) != JSON.stringify(res.data)){
              this.current_purchased_plan = res.data;
            }
          }
          resolve(res);
        });
      });
    }
  }

  // checkUpdateTableProductData(product_data_list){
  //   if (this.table_data_list != null && this.table_data_list.length > 0){
  //     product_data_list.forEach(product_data => {
  //       let index = this.table_data_list.map(d => d.id).indexOf(product_data.id);
  //       if (index != -1){
  //         this.table_data_list[index] = JSON.parse(JSON.stringify(product_data));
  //       }

  //     });
  //   }
  // }

  // getStoreWebsiteData(){

  // }
}
