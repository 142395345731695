import { Injectable } from '@angular/core';
import { Platform, NavController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, from, of } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { HttpService } from './http.service';
import { CommonService } from './common.service';
import { send } from 'process';
// import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authState = new BehaviorSubject(null);
  authState2 = new BehaviorSubject(null);

  constructor(
    private platform: Platform,
    private router: Router,
    private nav: NavController,
    private alertController: AlertController,
    private httpService: HttpService,
    // public dataService: DataService,
    public commonService: CommonService,
  ) {
    this.platform.ready().then(() => {
      // this.ifLoggedIn();
      this.ifAdminLoggedIn();
    });

  }

  //check admin
  ifAdminLoggedIn() {
    // add check 
    let internal_account_data = JSON.parse(localStorage.getItem("internal_account_data"));
    if (internal_account_data) {
      this.authState2.next(internal_account_data);
    }else{
      this.ifStoreLoggedIn();
    }
    // console.log(store_account_data);
  }
  //check store
  ifStoreLoggedIn() {
    // add check 
    let store_account_data = JSON.parse(localStorage.getItem("store_account_data"));
    if (store_account_data) {
      this.authState.next(store_account_data);
    }
    // console.log(store_account_data);
  }

  ifLoggedIn() {
    // add check 
    let store_account_data = JSON.parse(localStorage.getItem("store_account_data"));
    if (store_account_data) {
      this.authState.next(store_account_data);
    }
    // console.log(store_account_data);
  }

  Logout() {
    this.authState.next(null);
    window.localStorage.removeItem("store_account_data");
    if (!this.router.url.includes('login')){
      let internal_account_data = JSON.parse(localStorage.getItem("internal_account_data"));
      if (internal_account_data) {
        window.location.href = './staff-store-list';
      }else{
        window.location.href = './login';
      }
    }
  }

  StaffLogout() {
    this.authState2.next(null);
    this.authState.next(null);
    window.localStorage.removeItem("store_account_data");
    window.localStorage.removeItem("internal_account_data");
    // this.dataService.staff_data = null;
    if (!this.router.url.includes('login')){
      window.location.href = './login';
    }
  }

  async Login(email: string, password: string) {    
    const login = await this.httpService.StoreAccountLogin(email, password);
    // console.log(login);
    if (login.result == "success" && login.data != null && !login.data.disabled) {
      console.log(login.data);
      login.data['from'] = 3;
      localStorage.setItem('store_account_data', JSON.stringify(login.data));
      console.log(localStorage.getItem('store_account_data'));
      // let storageObs = from(this.storage.set(TOKEN_KEY, login.data));
      // return storageObs;
      return true;
    }
    else {
      this.commonService.openSnackBar("電郵或密碼錯誤");
      return null;
    }
  }

  async StaffLogin(email: string, password: string) {    
    const login = await this.httpService.StaffLogin(email, password);
    console.log(login);
    if (login.result == "success" && login.data != null && !login.data.disabled) {
      this.authState2.next(login.data);
      localStorage.setItem('internal_account_data', JSON.stringify(login.data));
      // let storageObs = from(this.storage.set(TOKEN_KEY, login.data));
      // return storageObs;
      return true;
    }
    else {
      // this.commonService.openSnackBar("電郵或密碼錯誤");
      return null;
    }
  }



  // directLogout() {
  //   this.authState.next(null);
  //   window.localStorage.removeItem("user_data");
  //   this.commonService.OpenPage('/home');
  // }

  SyncUserDate(event?) {
    if (this.getStoreAccountData() == null) {
      if (event != undefined) {
        event.target.complete();
      }
      return;
    }
    let send_data = {
      // data_type: this.getStoreAccountData().data_type, 
      application_key: this.getStoreAccountData().application_key
    };

    
    this.httpService.GetExtraStoreAccountData(send_data).then(res => {
      console.log(res);
      if (event != undefined) {
        event.target.complete();
      }
      if (res.result == "success" && res.data != null) {
        if (res.data.disabled) {
          this.commonService.openSnackBar("帳號已被停用", null, 5000);
          this.Logout();
          return;
        }
        if (res.data.password_data != this.getStoreAccountData().password_data) {
          this.commonService.openSnackBar("密碼已被更改，請重新登入", null,  5000);
          this.Logout();
          return;
        }
        let login_data = JSON.parse(JSON.stringify(res.data));
        console.log(login_data);
        this.updateUserData(login_data);
        this.authState.next(login_data);
      }
      else {
        this.Logout();
      }
    });
  }

  isAuthenticated() {
    return this.authState2.value;
  }

  getStoreAccountData() {
    return this.authState.value;
  }

  updateUserData(store_account_data) {
    let new_item = JSON.parse(JSON.stringify(store_account_data));
    this.authState.next(new_item);
    if(new_item['latest_plan'] == undefined || new_item['website_data']==undefined){
        let send_data2 = {
          data_type: "store_website_data",
          application_key: new_item.application_key
        }
        this.httpService.GetSingleDataByDataTypeAndApplicationKey(send_data2).then(async res => {
          console.log(res.data);
          if (res.result == "success") {
            new_item['website_data'] = JSON.parse(JSON.stringify(res.data));
          } else {
            this.commonService.openErrorSnackBar();
          }
          let send_data3 = {
            application_key: new_item.application_key
          }
          new_item['latest_plan'] = null;
          this.httpService.GetPurchasedPlanExcludingDisabledByApplicationKey(send_data3).then(async res => {
            this.commonService.isLoading = false;
            console.log(res.data);
            if (res.result == "success") {
              let plans = JSON.parse(JSON.stringify(res.data));
              if(plans.length>0){
                new_item['latest_plan'] = plans[0];
              }
            } else {
              this.commonService.openErrorSnackBar();
            }
            localStorage.setItem('store_account_data', JSON.stringify(new_item));
            this.authState.next(new_item);
          });
        });
    }else{
      new_item['from'] = 4;
      localStorage.setItem('store_account_data', JSON.stringify(new_item));
      this.authState.next(new_item);
    }
    // localStorage.setItem('store_account_data', JSON.stringify(store_account_data));
  }
}
