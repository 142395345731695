import { ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { Location } from '@angular/common';
import { AuthService } from './services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { trigger } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [];
  public storePages = [
    // {
    //   title: 'Blank',
    //   url: 'blank',
    //   icon: 'storefront'
    // },
    {
      zh_title: '我的商店',
      en_title: 'Store',
      url: 'my-store',
      icon: 'assets/icon/my_store.svg',
      icon_selected: 'assets/icon/my_store_selected.svg'
    },
    {
      zh_title: 'POS',
      en_title: 'POS',
      url: 'pos',
      icon: 'assets/icon/pos.svg',
      icon_selected: 'assets/icon/pos_selected.svg'
    },
    {
      zh_title: '我的版面',
      en_title: 'Page Setting',
      url: 'layout',
      icon: 'assets/icon/layout.svg',
      icon_selected: 'assets/icon/layout_selected.svg'
    },
    {
      zh_title: '會員',
      en_title: 'Member',
      url: 'user',
      icon: 'assets/icon/user.svg',
      icon_selected: 'assets/icon/user_selected.svg'
    },
    {
      zh_title: '商品',
      en_title: 'Product',
      url: 'product',
      icon: 'assets/icon/product.svg',
      icon_selected: 'assets/icon/product_selected.svg'
    },
    {
      zh_title: '課程',
      en_title: 'Course',
      url: 'course',
      icon: 'assets/icon/course_icon.svg',
      icon_selected: 'assets/icon/course_icon.svg'
    },
    {
      zh_title: '預約服務',
      en_title: '預約服務',
      check: true,
      url: 'medi-appointment',
      icon: 'assets/icon/calendar.svg',
      icon_selected: 'assets/icon/calendar_selected.svg'
    },
    {
      zh_title: '預約服務',
      en_title: 'Appointment',
      check: true,
      url: 'appointment',
      icon: 'assets/icon/calendar.svg',
      icon_selected: 'assets/icon/calendar_selected.svg'
    },
    {
      zh_title: '訂單',
      en_title: 'Order',
      url: 'order',
      icon: 'assets/icon/order.svg',
      icon_selected: 'assets/icon/order_selected.svg'
    },
    {
      zh_title: '推廣活動',
      en_title: 'Promotion',
      url: 'promotion',
      icon: 'assets/icon/promotion.svg',
      icon_selected: 'assets/icon/promotion_selected.svg'
    },
    {
      zh_title: '查詢',
      en_title: '查詢',
      check: true,
      url: 'medi-enquiry',
      icon: 'assets/icon/order.svg',
      icon_selected: 'assets/icon/order_selected.svg'
    },
    {
      zh_title: '查詢',
      en_title: 'Enquiry',
      check: true,
      url: 'enquiry',
      icon: 'assets/icon/enquiry_icon.svg',
      icon_selected: 'assets/icon/enquiry_icon.svg'
    },
    {
      zh_title: '設定',
      en_title: 'Setting',
      url: 'setting',
      icon: 'assets/icon/setting.svg',
      icon_selected: 'assets/icon/setting_selected.svg'
    },

  ];
  public staffPages = [
    {
      title: '商戶名單',
      url: 'staff-store-list',
      icon: 'assets/icon/user.svg',
      icon_selected: 'assets/icon/user_selected.svg'
    },
    // {
    //   title: '聯絡我們',
    //   url: 'internal-enquiry',
    //   icon: 'assets/icon/order.svg',
    //   icon_selected: 'assets/icon/order_selected.svg'
    // },
    // {
    //   title: '報價要求',
    //   url: 'internal-quotation',
    //   icon: 'assets/icon/order.svg',
    //   icon_selected: 'assets/icon/order_selected.svg'
    // },
  ];

  current_url = "";

  sub_side_menu = null;
  store_account_data = null;

  order_data_count = { awaiting_payment: 0, preparing_delivery: 0 };

  stopGetAwaitingPaymentOrder = false;

  current_lang = 'zh';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private location: Location,
    public dataService: DataService,
    public menuCtrl: MenuController,
    public commonService: CommonService,
    public httpService: HttpService,
    public auth: AuthService
  ) {
    // // Use matchMedia to check the user preference
    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // console.log(prefersDark.matches);
    // this.toggleDarkTheme(prefersDark.matches);
    // // Listen for changes to the prefers-color-scheme media query
    // prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
    // // Add or remove the "dark" class based on if the media query matches

    let dark_mode = localStorage.getItem("dark_mode");
    if (dark_mode != undefined || dark_mode != null) {
      this.commonService.dark_mode = (dark_mode == 'false' ? false : true);
      document.body.classList.toggle('dark-theme', (dark_mode == 'false' ? false : true));
      // this.commonService.toggleDarkTheme();
    }

    this.initializeApp();


    this.router.events.subscribe(val => {
      this.store_account_data = JSON.parse(localStorage.getItem("store_account_data"));
      // console.log(this.store_account_data);
      // console.log(this.store_account_data);
      if (this.store_account_data) {
        this.appPages = this.storePages;
        if (this.store_account_data.latest_plan != null) {
          // if(store_account_data.latest_plan.plan_data.module_list.includes("medi-enquiry")){
          //   let find_index = this.appPages.findIndex(d => d.url == "medi-enquiry");
          //   console.log(find_index);
          //   if(find_index == -1){
          //     this.appPages.push({
          //         title: '查詢',
          //         url: 'medi-enquiry',
          //         icon: 'assets/icon/order.svg',
          //         icon_selected: 'assets/icon/order_selected.svg'
          //       });
          //   }
          // }
          // if(store_account_data.latest_plan.plan_data.module_list.includes("enquiry")){
          //    let find_index = this.appPages.findIndex(d => d.url == "enquiry");
          //    console.log(find_index);
          //    if(find_index == -1){
          //      this.appPages.push({
          //          title: '查詢',
          //          url: 'enquiry',
          //          icon: 'assets/icon/order.svg',
          //          icon_selected: 'assets/icon/order_selected.svg'
          //        });
          //    }
          //   // this.appPages.push({
          //   //     title: '查詢',
          //   //     url: 'enquiry',
          //   //     icon: 'assets/icon/order.svg',
          //   //     icon_selected: 'assets/icon/order_selected.svg'
          //   //   });
          // }
        }
      } else {
        let internal_account_data = JSON.parse(localStorage.getItem("internal_account_data"));
        if (internal_account_data) {
          // console.log(this.router.url);
          this.appPages = this.staffPages;
        } else {
        }
      }
      if (location.path() != "") {
        let internal_account_data = JSON.parse(localStorage.getItem("internal_account_data"));
        if (internal_account_data) {
          this.dataService.staff_data = internal_account_data;
          // console.log(this.router.url);
          // this.current_url = this.router.url;
          // this.store_account_data = JSON.parse(localStorage.getItem("store_account_data"));
          if (this.store_account_data) {
            this.current_url = this.router.url;
            // console.log(this.current_url);
            this.selectedIndex = this.appPages.findIndex(d => d.url == location.path());
            if (
              this.current_url.includes('my-store')
              || this.current_url.includes('setting')
              || this.current_url.includes('product')
              || this.current_url.includes('medi-appointment')
              || this.current_url.includes('appointment')
              || this.current_url.includes('order/order-list')
              || this.current_url.includes('promotion')
              || this.current_url.includes('layout')
              || this.current_url.includes('course')
            ) {
              this.sub_side_menu = true;
            }
            else {
              this.sub_side_menu = false;
            }
            console.log(this.store_account_data);
            if (this.store_account_data.cms_side_menu != undefined) {
              let path_part = location.path().split('/');
              if (!this.store_account_data.cms_side_menu.includes(path_part[1]) && (this.store_account_data.latest_plan != undefined && this.store_account_data.latest_plan != null && !this.store_account_data.latest_plan.plan_data.module_list.includes(path_part[1]))) {
                console.log(path_part);
                // this.current_url = `/`+this.store_account_data.cms_side_menu[0];
                window.location.href = this.store_account_data.cms_side_menu[0];
              }
            }
          } else {
            this.current_url = this.router.url;
          }
        } else {
          // this.store_account_data = JSON.parse(localStorage.getItem("store_account_data"));
          if (this.store_account_data) {
            this.current_url = this.router.url;
            // console.log(this.current_url);
            this.selectedIndex = this.appPages.findIndex(d => d.url == location.path());
            if (
              this.current_url.includes('my-store')
              || this.current_url.includes('setting')
              || this.current_url.includes('product')
              || this.current_url.includes('medi-appointment')
              || this.current_url.includes('appointment')
              || this.current_url.includes('order/order-list')
              || this.current_url.includes('promotion')
              || this.current_url.includes('layout')
              || this.current_url.includes('course')
            ) {
              this.sub_side_menu = true;
            }
            else {
              this.sub_side_menu = false;
            }
            if (this.store_account_data.cms_side_menu != undefined) {
              let path_part = location.path().split('/');
              if (!this.store_account_data.cms_side_menu.includes(path_part[1]) && (this.store_account_data.latest_plan != undefined && this.store_account_data.latest_plan != null && !this.store_account_data.latest_plan.plan_data.module_list.includes(path_part[1]))) {
                console.log(path_part);
                // this.current_url = `/`+this.store_account_data.cms_side_menu[0];
                window.location.href = this.store_account_data.cms_side_menu[0];
              }
            }
          } else {
            this.current_url = this.router.url;
          }
        }

      } else {
        let internal_account_data = JSON.parse(localStorage.getItem("internal_account_data"));
        if (internal_account_data) {
          console.log(this.router.url);
          this.current_url = this.router.url;
          // this.
        }
      }
    });

    if (this.commonService.show_aj) {
      this.appPages = [
        {
          title: 'POS',
          url: 'pos',
          icon: 'assets/icon/layout.svg',
          icon_selected: 'assets/icon/layout_selected.svg'
        },
        {
          title: '我的版面',
          url: 'layout',
          icon: 'assets/icon/pos.svg',
          icon_selected: 'assets/icon/pos_selected.svg'
        },
        {
          title: '顧客',
          url: 'user',
          icon: 'assets/icon/user.svg',
          icon_selected: 'assets/icon/user_selected.svg'
        },
        {
          title: '商品',
          url: 'product',
          icon: 'assets/icon/product.svg',
          icon_selected: 'assets/icon/product_selected.svg'
        },
        {
          title: '預約服務',
          url: 'medi-appointment',
          icon: 'assets/icon/calendar.svg',
          icon_selected: 'assets/icon/calendar_selected.svg'
        },
        {
          title: '訂單',
          url: 'order',
          icon: 'assets/icon/order.svg',
          icon_selected: 'assets/icon/order_selected.svg'
        },
        {
          title: '推廣活動',
          url: 'promotion',
          icon: 'assets/icon/promotion.svg',
          icon_selected: 'assets/icon/promotion_selected.svg'
        },
        {
          title: '設定',
          url: 'setting',
          icon: 'assets/icon/setting.svg',
          icon_selected: 'assets/icon/setting_selected.svg'
        },

      ];
    }

    // console.log(this.auth.authState);


  }

  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark-theme', shouldAdd);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {

    // setTimeout(() => {
    //   console.log(123);
    //   this.toggleDarkTheme(false);
    // }, 5000);
    this.store_account_data = JSON.parse(localStorage.getItem("store_account_data"));
    if (this.store_account_data != null) {
      let key = `site_lang_${this.store_account_data.application_key}`;
      this.current_lang = localStorage.getItem(key);
      if (this.current_lang == null) {
        localStorage.setItem(key, 'zh');
        this.current_lang = 'zh';
      }
    }
  }

  ngAfterContentInit() {
    /*
    if (!this.stopGetAwaitingPaymentOrder) {
      this.getAwaitingPaymentOrder();
      this.stopGetAwaitingPaymentOrder = true;
    }
    */
  }

  ngAfterContentChecked() {

    if (this.current_url.includes('my-store') && !this.stopGetAwaitingPaymentOrder && this.auth.getStoreAccountData().application_key != null) {
      this.getAwaitingPaymentOrder();
      this.stopGetAwaitingPaymentOrder = true;
    }

  }
  refresh() {
    location.reload();
  }

  getAwaitingPaymentOrder() {
    let status_arr = ["awaiting_payment", "unverified_payment", "preparing"];

    for (let i = 0; i < status_arr.length; i++) {
      let send_data = {
        sorting: "create_date",
        direction: "DESC",
        limit: 10,
        page: 1,
        application_key: this.auth.getStoreAccountData().application_key,
        filter_status: status_arr[i]
      }

      this.httpService.GetOrderDataAndTotalNumberBySortingAndLimit(send_data).then(res => {
        if (res.result == 'success') {
          if (i == 2) {
            this.order_data_count.preparing_delivery += res.data.total_number;
          } else {
            this.order_data_count.awaiting_payment += res.data.total_number;
          }
        } else {
          this.commonService.openErrorSnackBar();
        }
      });
    }


    // awaiting_payment

    /*
    
        // unverified_payment
        this.httpService.GetOrderDataAndTotalNumberBySortingAndLimit(send_data).then(res => {
          if (res.result == 'success') {
            this.order_data_count.awaiting_payment += res.data.total_number;
          } else {
            this.commonService.openErrorSnackBar();
          }
        });
    
        // preparing
        this.httpService.GetOrderDataAndTotalNumberBySortingAndLimit(send_data).then(res => {
          if (res.result == 'success') {
            this.order_data_count.preparing_delivery += res.data.total_number;
          } else {
            this.commonService.openErrorSnackBar();
          }
        });
      }
    
      selectionChanged(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
        if (dateRangeStart.value != null && dateRangeStart.value != '' && dateRangeEnd.value != null && dateRangeEnd.value != '') {
          this.segment_value = null;
          this.filter_start_date = dateRangeStart.value.substring(0, 10);
          this.filter_end_date = dateRangeEnd.value.substring(0, 10);
        }
        // console.log(this.filter_start_date);
        */
  }


  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event.target.innerWidth <= 992) {
      this.commonService.show_sidemenu = false;
    }
  }


}
